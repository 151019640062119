<template>
  <v-card flat>
    <v-card-text>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="users"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
      >
        <template v-slot:item.dep="{ item }">
          {{item.department.showName}}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="text-right">
      <v-spacer></v-spacer>
      <v-btn
        color="info"
        dark
        class="mb-2"
        @click="handleCancelClick"
      >
        {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="handleSubmitClick"
      >
        {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    props: {
      depInfo: {
        type: Object,
        default: () => null
      }
    },
    data () {
      return {
        singleSelect: false,
        selected: [],
        desserts: [
        ],
      }
    },
    computed: {
      ...mapGetters({
        users: 'user/users'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.user.realName'),
            align: 'start',
            sortable: false,
            value: 'realName',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.dep'),
            align: 'start',
            sortable: false,
            value: 'dep',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.credentialNum'),
            align: 'start',
            sortable: false,
            value: 'credentialNum',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.phone'),
            align: 'start',
            sortable: false,
            value: 'phone',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.email'),
            align: 'start',
            sortable: false,
            value: 'email',
          }
        ]
      }
    },

    created() {
      this.$nextTick( () => {
        this.initialize()
      })
    },

    methods: {
      ...mapActions({
        userQueryAllByDepId: 'user/queryAllByDepId',
        departmentResetAdmin: 'department/resetAdmin'
      }),

      initialize () {
        this.userQueryAllByDepId(this.depInfo.id).then( () => {
          this.users.forEach(user => {
            if ( this.depInfo.depAdminIds.includes(user.id) )  {
              this.selected.push(user)
            }
          });
        })
      },

      handleCancelClick () {
        this.$emit('cancel', true )
      },

      handleSubmitClick() {
        console.log('JSON.stringify(this.selected.length )')
        console.log(JSON.stringify(this.selected ))
        console.log(JSON.stringify(this.depInfo.depAdminIds ))
        if (this.selected.length > 0 && ( this.depInfo.depAdminIds.length <= 0 || this.selected.id !== this.depInfo.depAdminIds[0])) {
          this.departmentResetAdmin({id: this.depInfo.id, params: {userId :this.selected[0].id}}).then( () => {
            this.$emit('submit', true )
          })
        }
      }
    }
  }
</script>
